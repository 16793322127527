import { Palette } from '@mui/material/styles';
import { useAllOrganizationsQuery } from '@watershed/shared-frontend/generated/urql';
import useLocalStorageState from '@watershed/shared-frontend/hooks/useLocalStorageState';
import { getGqlResultData } from '@watershed/shared-frontend/utils/errorUtils';
import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';
import IconButton from '@watershed/ui-core/components/IconButton';
import StarEmptyIcon from '@watershed/icons/components/StarEmpty';
import StarFillIcon from '@watershed/icons/components/StarFill';
import groupBy from 'lodash/groupBy';
import React from 'react';
import { z } from 'zod';
import sortBy from 'lodash/sortBy';
import omit from 'lodash/omit';
import { getCurrentDevEnv } from '@watershed/shared-frontend/utils/devEnv';
import BlankSlate from '@watershed/ui-core/components/BlankSlate';
import { GQWatershedPlanLegacy } from '@watershed/shared-universal/generated/graphql';
import isNotNullish from '@watershed/shared-universal/utils/isNotNullish';
import { Typography } from '@mui/material';
import TextLink from '@watershed/ui-core/components/TextLink';

/**
 * Utilities for managing notable, "reference" orgs, whether that is a shared
 * set of global references or series of personal favorites.
 */

export function useFavoriteOrgs() {
  const [favoriteOrgIds, setFavoriteOrgIds] = useLocalStorageState(
    'admin-favorite-orgs',
    [],
    (value) => {
      return z.array(z.string()).parse(value);
    }
  );
  return {
    favoriteOrgIds,
    addFavoriteOrgs: (orgIds: Array<string>) => {
      setFavoriteOrgIds((curValue) => {
        const valueSet = new Set(curValue);
        orgIds.forEach((orgId) => valueSet.add(orgId));
        return sortBy(Array.from(valueSet));
      });
    },
    toggleFavorite: (orgId: string) => {
      setFavoriteOrgIds((curValue) => {
        const valueSet = new Set(curValue);
        if (valueSet.has(orgId)) {
          valueSet.delete(orgId);
        } else {
          valueSet.add(orgId);
        }
        return sortBy(Array.from(valueSet));
      });
    },
    isFavorite: (orgId: string) => {
      return favoriteOrgIds.includes(orgId);
    },
  };
}

export const FavoriteOrgButton: React.FC<{
  orgId: string;
  size?: 'small' | 'large';
}> = (props) => {
  const size = props.size ?? 'small';
  const { isFavorite, toggleFavorite } = useFavoriteOrgs();
  const sharedIconProps = {
    size: size === 'small' ? 16 : 24,
  };
  return (
    <IconButton
      size="small"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        toggleFavorite(props.orgId);
      }}
      sx={{ padding: 0.5 }}
      tooltip="Favorite this org for easy access on the admin homepage"
    >
      {isFavorite(props.orgId) ? (
        <StarFillIcon
          {...sharedIconProps}
          color={(theme) => theme.palette.warning.main}
        />
      ) : (
        <StarEmptyIcon
          {...sharedIconProps}
          color={(theme) => theme.palette.secondary.main}
        />
      )}
    </IconButton>
  );
};

/**
 * TODO: Ideally, this exists persisted in the DB.
 * For ease of prototyping, we can use this current shape of
 * data and then commit something reasonable to a db, potentially
 * even letting certain folks update them globally :)
 */
interface ReferenceOrgLabel {
  label: string;
  color: keyof Palette;
  description: string;
}
interface ReferenceOrg {
  orgId: string;
  orgName: string;
  description: string;
  labels: Array<ReferenceOrgLabel>;
}
interface ReferenceOrgGroup {
  name: string;
  orgs: Array<ReferenceOrg>;
  emptyState?: React.ReactNode;
  warning?: {
    title: React.ReactNode;
    description: React.ReactNode;
  };
}

/**
 * Hardcoded for now - would be nice to put this in a DB!
 */
const ORG_LABELS = {
  demo: {
    color: 'grass',
    label: 'Demo',
    description: 'Demo org',
  },
  test: {
    color: 'afternoon',
    label: 'Test',
    description: 'Test org',
  },
  customer: {
    color: 'poppy',
    label: 'Customer',
    description: 'Real customer',
  },
  supplier: {
    color: 'lightGrey',
    label: 'Supplier',
    description: 'Supplier',
  },
  canonicalDemo: {
    color: 'cobalt70',
    label: 'Canonical Demo',
    description:
      'Canonical demo: See - https://docs.google.com/spreadsheets/d/1FmICIK_fV-Dk1rdf-97Yi6lZfczDxXUFVmiO8KOExc8/edit?gid=0#gid=0',
  },
  csrd: {
    color: 'forest',
    label: 'CSRD',
    description: 'CSRD',
  },
  benchmarks: {
    color: 'grey50',
    label: 'Benchmarks',
    description: 'Demo for benchmarks',
  },
  customDatasets: {
    color: 'grey50',
    label: 'Custom Datasets',
    description: 'Demo for custom datasets',
  },
  dataAnomalies: {
    color: 'grey50',
    label: 'Data anomalies',
    description: 'Demo for custom datasets',
  },
  savedViews: {
    color: 'grey50',
    label: 'Saved views',
    description: 'Demo for saved views',
  },
  supplyChain: {
    color: 'grey50',
    label: 'Supply Chain',
    description: 'Demo for supply chain',
  },
  productModule: {
    color: 'grey50',
    label: 'Product Module',
    description: 'Demo for Product module',
  },
  reductionPlans: {
    color: 'grey50',
    label: 'Reduction Plans',
    description: 'Demo for Reduction Plans',
  },
  reporting: {
    color: 'grey50',
    label: 'Reporting',
    description: 'Demo for Reduction Plans',
  },
} satisfies Record<string, ReferenceOrgLabel>;

/**
 * These org labels are kind of defaults, and we don't need them as separate tabs.
 */
const OMITTABLE_ORG_LABELS = [
  'demo',
  'test',
  'supplier',
  'customer',
] satisfies Array<keyof typeof ORG_LABELS>;

/**
 * Hardcoded for now - would be nice to persist in a DB!
 */
const REFERENCE_ORGS: Array<ReferenceOrg> = [
  {
    orgId: 'org_2W84ojNUvAqx64mJ4UHn',
    orgName: 'EverythingCo',
    description: 'Luxury / Beauty focused CPG',
    labels: [
      ORG_LABELS.canonicalDemo,
      ORG_LABELS.benchmarks,
      ORG_LABELS.savedViews,
      ORG_LABELS.supplyChain,
      ORG_LABELS.productModule,
      ORG_LABELS.reductionPlans,
    ],
  },
  {
    orgId: 'org_2Mz4mWnaeRDsijyeYnNe',
    orgName: 'FoodCo Demo',
    description:
      'Food / Hosptiality. FoodCo is more the companies that make the food.',
    labels: [
      ORG_LABELS.canonicalDemo,
      ORG_LABELS.benchmarks,
      ORG_LABELS.savedViews,
      ORG_LABELS.supplyChain,
      ORG_LABELS.reductionPlans,
    ],
  },
  {
    orgId: 'org_2WV4r7GvPt6LWWsJTvXG',
    orgName: 'Healthcare Co',
    description: 'Healthcare: hospital systems, pharma, medical devices',
    labels: [
      ORG_LABELS.canonicalDemo,
      ORG_LABELS.benchmarks,
      ORG_LABELS.customDatasets,
      ORG_LABELS.dataAnomalies,
      ORG_LABELS.supplyChain,
      ORG_LABELS.productModule,
    ],
  },
  {
    orgId: 'org_2aA4rvNXyrnnCy65L3Jj',
    orgName: 'LogisticsCo',
    description:
      'Delivery Services (e.g. doordash), Freight Companies, Relocation Services, Logistics Companies',
    labels: [
      ORG_LABELS.canonicalDemo,
      ORG_LABELS.benchmarks,
      ORG_LABELS.customDatasets,
      ORG_LABELS.dataAnomalies,
      ORG_LABELS.savedViews,
      ORG_LABELS.reductionPlans,
      ORG_LABELS.reporting,
    ],
  },
  {
    orgId: 'org_2664tJogzhsTTUPd1sCw',
    orgName: 'Real Estate Co',
    description: 'REITs, Brokers, Companies with large real estate holdings',
    labels: [
      ORG_LABELS.canonicalDemo,
      ORG_LABELS.benchmarks,
      ORG_LABELS.customDatasets,
      ORG_LABELS.dataAnomalies,
      ORG_LABELS.reporting,
    ],
  },
  {
    orgId: 'org_2W54oNRUuiP9Gif2rNzU',
    orgName: 'Manufacturing Co',
    description:
      'Any company that has large factories or manufactures products. Examples include: tires, metals, gizmos, etc.',
    labels: [
      ORG_LABELS.canonicalDemo,
      ORG_LABELS.benchmarks,
      ORG_LABELS.savedViews,
      ORG_LABELS.supplyChain,
      ORG_LABELS.productModule,
    ],
  },
  {
    orgId: 'org_2sL5LVgVHDNZRDGb9QaY',
    orgName: 'CSRD Demo',
    description: 'Demo org for CSRD',
    labels: [ORG_LABELS.csrd, ORG_LABELS.reporting],
  },
];

/**
 * Return a set of reference orgs, merged with local favorites.
 * TODO: Persisting/Fetching this from the backend could make things
 * much more self-serve :)
 */
export function useReferenceOrgs(): Array<ReferenceOrgGroup> {
  const { favoriteOrgIds } = useFavoriteOrgs();

  const [result] = useAllOrganizationsQuery();
  const data = getGqlResultData(result);
  const orgs = flattenConnection(data?.organizations);
  const orgsById = groupBy(orgs, (org) => org.id);

  // Can remove if we fetch from the backend!
  const mapInExtraLabels = (org: ReferenceOrg) => {
    const orgFromData = orgsById[org.orgId]?.at(0);
    const extraLabels = [];
    if (orgFromData?.demoOrg) {
      extraLabels.push(ORG_LABELS.demo);
    }
    if (orgFromData?.testOrg) {
      extraLabels.push(ORG_LABELS.test);
    }
    if (orgFromData && !orgFromData.demoOrg && !orgFromData.testOrg) {
      extraLabels.push(ORG_LABELS.customer);
    }
    if (orgFromData?.watershedPlanLegacy === GQWatershedPlanLegacy.NoPlan) {
      extraLabels.push(ORG_LABELS.supplier);
    }
    return {
      ...org,
      labels: [...org.labels, ...extraLabels],
    };
  };

  // Since the reference orgs are hardcoded in our frontend, only show them
  // when we're not in our local dev.
  // If we fetch this from a backend endpoint, we could remove this check.
  const referenceOrgs =
    getCurrentDevEnv() === 'local-dev' ? [] : REFERENCE_ORGS;
  const labels =
    getCurrentDevEnv() === 'local-dev'
      ? []
      : // We try to respect the ORG_LABELS ordering, rather than aggregating the categories
        // off of the reference orgs themselves.
        Object.values(omit(ORG_LABELS, ...OMITTABLE_ORG_LABELS));

  const referenceOrgsById = groupBy(referenceOrgs, (org) => org.orgId);

  const referenceOrgWarning: ReferenceOrgGroup['warning'] = {
    title: (
      <>
        Please avoid using{' '}
        <TextLink href="https://docs.google.com/spreadsheets/d/1FmICIK_fV-Dk1rdf-97Yi6lZfczDxXUFVmiO8KOExc8/edit?pli=1&gid=0#gid=0">
          prospect-facing demo accounts
        </TextLink>{' '}
        to test new features or playing around!
      </>
    ),
    description: (
      <>
        <Typography gutterBottom>
          If you do, then please clean things up after! We want to avoid
          confusion/surprises and keep the product clean during live demos (ex,
          surprise feature flags, saved views with odd names, empty reduction
          plans, random report entries, etc)
        </Typography>
        <Typography gutterBottom>
          Please enable FFs locally (bottom right corner in dashboard) or even
          better - spin-up your own testing environments where you can go crazy!
          😉
        </Typography>
        <Typography>Thank you 🙏 (@csol)</Typography>
      </>
    ),
  };

  const groups: Array<ReferenceOrgGroup> = [
    {
      name: 'Favorites',
      orgs: sortBy(
        [
          ...favoriteOrgIds.map((orgId) => {
            // If we already found it as a reference org, use it.
            const refOrg = referenceOrgsById[orgId]?.at(0);
            if (refOrg) {
              return refOrg;
            }
            // Otherwise, try to augment data using org data
            const org = orgsById[orgId]?.at(0);
            return {
              orgId,
              orgName: org?.name ?? orgId,
              // Check against existing reference orgs
              description: org?.name ?? orgId,
              labels: [],
            };
          }),
        ].map(mapInExtraLabels),
        (org) => org.orgName
      ),
      emptyState: (
        <BlankSlate
          icon={StarEmptyIcon}
          size="small"
          title="No favorites"
          subtitle="Star an org to see it appear here!"
        />
      ),
    },
    labels.length
      ? {
          name: 'All',
          orgs: referenceOrgs.map(mapInExtraLabels),
          warning: referenceOrgWarning,
        }
      : undefined,
    ...labels.map(({ label }) => {
      return {
        name: label,
        orgs: referenceOrgs
          .filter((org) =>
            org.labels.some((orgLabel) => orgLabel.label === label)
          )
          .map(mapInExtraLabels),
        warning: referenceOrgWarning,
      };
    }),
  ].filter(isNotNullish);

  return groups;
}
