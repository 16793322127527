import {
  GridSlotsComponentsProps,
  gridRowSelectionStateSelector,
  useGridApiContext,
  useGridSelector,
} from '../DataGrid/DataGrid';
import { Trans } from '@lingui/react/macro';
import { Fade, Stack, Typography, Divider } from '@mui/material';
import Button, { ButtonProps } from '../Button';
import { mixinSx } from '@watershed/style/styleUtils';

export function ObjectListSelectionFooter({
  objectListSelectionFooterActions,
  sx,
}: NonNullable<GridSlotsComponentsProps['footer']>) {
  const apiRef = useGridApiContext();
  const selectionModel = useGridSelector(apiRef, gridRowSelectionStateSelector);
  const rowIds = apiRef.current.getAllRowIds();
  const selectableRowCount = rowIds.filter((id) =>
    apiRef.current.isRowSelectable(id)
  ).length;
  const hasSelectableRows = !!selectableRowCount;
  const selectedRowCount = apiRef.current.getSelectedRows().size;
  const allRowsSelected = selectedRowCount === selectableRowCount;

  const selectAllRows = () => {
    apiRef.current.selectRows(rowIds, true);
  };
  const deselectAllRows = () => {
    apiRef.current.selectRows(rowIds, false);
  };

  return (
    <Stack
      sx={mixinSx(
        {
          position: 'fixed',
          bottom: 16,
          left: 0,
          right: 0,
          pointerEvents: 'none',
        },
        sx
      )}
    >
      <Fade in={hasSelectableRows && selectionModel.length > 0}>
        <Stack
          direction="row"
          sx={{
            pointerEvents: 'auto',
            margin: '0 auto',
            height: 44,
            paddingX: 2.5,
            alignItems: 'center',
            borderRadius: '50px',
            color: (theme) => theme.palette.grey[100],
            // eslint-disable-next-line @watershed/no-custom-colors
            border: '1px solid var(--gray-70, #4F596E)',
            // eslint-disable-next-line @watershed/no-custom-colors
            background: 'linear-gradient(180deg, #041330 0%, #232C40 100%)',
            boxShadow:
              '0px 0px 0px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.06), 0px 3px 3px -1.5px rgba(0, 0, 0, 0.06), 0px 6px 6px -3px rgba(0, 0, 0, 0.06), 0px 12px 12px -6px rgba(0, 0, 0, 0.06), 0px 24px 24px -12px rgba(0, 0, 0, 0.06)',
          }}
        >
          <Stack direction="row" gap={2}>
            <Typography
              sx={{ color: 'inherit', display: 'grid', alignItems: 'center' }}
            >
              <Trans context="Number of rows selected in data grid">
                {selectedRowCount} selected
              </Trans>
            </Typography>
            <Divider orientation="vertical" flexItem />
          </Stack>
          <FooterButton
            onClick={allRowsSelected ? deselectAllRows : selectAllRows}
          >
            {allRowsSelected ? (
              <Trans context="Button copy">
                Deselect all ({selectableRowCount})
              </Trans>
            ) : (
              <Trans context="Button copy">
                Select all ({selectableRowCount})
              </Trans>
            )}
          </FooterButton>
          {objectListSelectionFooterActions && (
            <Stack
              direction="row"
              sx={{ minWidth: 382, justifyContent: 'flex-end' }}
            >
              {objectListSelectionFooterActions.map((actionProps, index) => (
                <FooterButton key={index} {...actionProps} />
              ))}
            </Stack>
          )}
        </Stack>
      </Fade>
    </Stack>
  );
}

function FooterButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      sx={mixinSx(
        {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          color: (theme) => theme.palette.white,
          fontSize: (theme) => theme.typography.caption.fontSize,
          '&:hover': {
            // eslint-disable-next-line @watershed/no-custom-colors
            backgroundColor: (theme) => theme.palette.grey80,
            boxShadow: 'none',
            color: (theme) => theme.palette.white,
          },
          '&:focus': {
            backgroundColor: 'transparent',
            border: '0',
            boxShadow: 'none',
            color: (theme) => theme.palette.white,
          },
          '&:focus-visible': {
            backgroundColor: 'transparent',
            boxShadow: `0 0 0 1px rgba(145, 156, 179, 0.6), 0 0 0 2px #041330, 0 0 0 3px rgba(145, 156, 179, 0.6)`,
            color: (theme) => theme.palette.white,
          },
          '&&&.Mui-disabled': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            // eslint-disable-next-line @watershed/no-custom-colors
            color: 'rgb(175, 183, 200)',
          },
        },
        props.sx
      )}
    />
  );
}
